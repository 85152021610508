<template>
    <div class="box">
        <h2>Aufgaben</h2>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th>Aufgabe</th>
                        <th>Anzahl</th>
                        <th>Warnung</th>
                        <th>Fehler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(task, idx) in tasks" v-bind:key="idx">
                        <td>{{ task.label }}</td>
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="task.count" :id="'task_' + task.id" placeholder=" " type="text" />
                                <label :for="'task' + task.id">Anzahl</label>
                            </div>
                        </td>
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="task.warning" :id="'task_' + task.id" placeholder=" " type="text" />
                                <label :for="'task' + task.id">Warnung</label>
                            </div>
                        </td>
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="task.error" :id="'task_' + task.id" placeholder=" " type="text" />
                                <label :for="'task' + task.id">Fehler</label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />

            <div class="button-row">
                <button @click="saveTasks()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tasks: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/tasks").then(
                response => {
                    this.tasks = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Aufgaben");
                }
            );
        },
        saveTasks() {
            var tasks = [];
            for (var i = 0; i < this.tasks.length; i++) {
                tasks.push({
                    id: this.tasks[i].id,
                    count: parseInt(this.tasks[i].count, 10),
                    warning: parseInt(this.tasks[i].warning, 10),
                    error: parseInt(this.tasks[i].error, 10)
                });
            }
            this.$api.post("/tasks", tasks).then(
                () => {
                    this.$snotify.success("Tasks saved");
                },
                () => {
                    this.$snotify.error("Save failed");
                }
            );
        }
    }
};
</script>
